
export function teaserModal() {
    const teaserContainers = document.querySelectorAll('.teaser-container');
    const body = document.querySelector('body');

    const getModal = (element) => {
        const modalId = element.getAttribute('data-modal');
        return document.querySelector(`.teaser-modal[data-modal="${modalId}"]`);
    }

    teaserContainers.forEach((container) => {

        const teaserBtns = container.querySelectorAll('.teaser-btn');
        teaserBtns.forEach((btn)=> {
            btn.addEventListener('click', () => {
                // zuerst zum Container scrollen
                container.scrollIntoView({ block: 'start', behavior: 'smooth' });

                // einen Timeout hinzufügen, um das Modal zu zeigen, nachdem das Scrollen abgeschlossen ist.
                setTimeout(() => {
                    const modal = getModal(btn);
                    modal.classList.add('show');
                    body.classList.add('no-scrolling');
                    console.log('body-no scrolling')
                }, 300);
            });
        });

        const closeModalBtns = container.querySelectorAll('.close-btn');
        closeModalBtns.forEach((btn)=> {
            btn.addEventListener('click', () => {
                const modal = getModal(btn);
                modal.classList.remove('show');
                body.classList.remove('no-scrolling');
            });
        });

        const openSwiperBtn = container.querySelectorAll('button.swiper-images');
        openSwiperBtn.forEach((swiperBtn) => {
            swiperBtn.addEventListener('click', () => {
                const modal = getModal(swiperBtn.closest('.teaser-modal'));
                const detailSwiper = modal.querySelector('.detail-swiper');
                detailSwiper.classList.add('show');
                detailSwiper.scrollIntoView({ block: 'start' });
                setTimeout(function () {
                    modal.classList.add('no-scrolling');
                },1100);
            });
        });

        const closeDetailSwiperBtns = container.querySelectorAll('.close-detail-swiper');
        closeDetailSwiperBtns.forEach((btn) => {
            btn.addEventListener('click', () => {
                const modal = getModal(btn.closest('.teaser-modal'));
                const detailSwiper = modal.querySelector('.detail-swiper');
                detailSwiper.classList.remove('show');
                modal.classList.remove('no-scrolling');
            });
        });
    });
}

/*export function teaserModal() {
    const teaserContainers = document.querySelectorAll('.teaser-container');
    const body = document.querySelector('body');

    const getModal = (element) => {
        const modalId = element.getAttribute('data-modal');
        return document.querySelector(`.teaser-modal[data-modal="${modalId}"]`);
    }

    teaserContainers.forEach((container) => {

        const teaserBtns = container.querySelectorAll('.teaser-btn');
        teaserBtns.forEach((btn)=> {
            btn.addEventListener('click', () => {
                const modal = getModal(btn);
                modal.classList.add('show');
                modal.scrollIntoView({ block: 'center' });
                body.classList.add('no-scrolling');
            });
        });

        const closeModalBtns = container.querySelectorAll('.close-btn');
        closeModalBtns.forEach((btn)=> {
            btn.addEventListener('click', () => {
                const modal = getModal(btn);
                modal.classList.remove('show');
                body.classList.remove('no-scrolling');
            });
        });

        const openSwiperBtn = container.querySelectorAll('button.swiper-images');
        openSwiperBtn.forEach((swiperBtn) => {
            swiperBtn.addEventListener('click', () => {
                const modal = getModal(swiperBtn.closest('.teaser-modal'));
                const detailSwiper = modal.querySelector('.detail-swiper');
                detailSwiper.classList.add('show');
                detailSwiper.scrollIntoView({ block: 'start' });
                setTimeout(function () {
                    modal.classList.add('no-scrolling');
                },1100)

            });
        });

        const closeDetailSwiperBtns = container.querySelectorAll('.close-detail-swiper');
        closeDetailSwiperBtns.forEach((btn) => {
            btn.addEventListener('click', () => {
                const modal = getModal(btn.closest('.teaser-modal'));
                const detailSwiper = modal.querySelector('.detail-swiper');
                detailSwiper.classList.remove('show');
                modal.classList.remove('no-scrolling');
            });
        });
    });
}*/
