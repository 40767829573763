import {equalHeightTeaserHeadlines} from "./equal-height-teaser";

export function newsList() {
    const container = document.querySelector(".news-items");
    const loadMoreButton = document.querySelector('.load-more-button');
    let newsData = [];
    let currentIndex = 0;
    const loadCount = 20;

    if (container && loadMoreButton) {
        fetch("/api/news-list")
            .then(response => response.json())
            .then(data => {
                newsData = data;
                loadItems();
            });

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    loadItems();
                    observer.unobserve(entry.target);
                }
            });
        });
        observer.observe(loadMoreButton);
    }

    function loadItems() {
        let items = newsData.slice(currentIndex, currentIndex + loadCount);
        currentIndex += items.length;

        items.forEach(item => {
            const teaser = document.createElement('div');
            teaser.className = 'teaser';
            teaser.innerHTML = `
                <div class="image-container">
                    <img class="lazy" src="${item.image}" alt="">
                </div>
                <div class="teaser-text">
                    <p class="date">${item.date}</p>
                    <p class="headline">
                       <a href="${item.detailLink}">${item.headline}</a>
                    </p>
                    <div class="title-text">
                        <p>
                            <a href="${item.detailLink}">${item.intro}</a>
                        </p>
                    </div>
                    <div class="buttons">
                    <button class="teaser-btn">
                    <a href="${item.detailLink}">
                        WEITERLESEN
                        <svg width="17.012" height="11.813" viewBox="0 0 17.012 11.813">
                        <g id="Gruppe_10341" data-name="Gruppe 10341" transform="translate(-5 0.709)">
                            <g id="Icon_feather-arrow-right" data-name="Icon feather-arrow-right" transform="translate(5)">
                                <path id="Pfad_4316" data-name="Pfad 4316" d="M7.5,18H23.093" transform="translate(-7.5 -12.802)" fill="none" stroke="#B2AC8A" stroke-linejoin="round" stroke-width="2"/>
                                <path id="Pfad_4317" data-name="Pfad 4317" d="M18,7.5l5.225,5.2L18,17.9" transform="translate(-7.631 -7.5)" fill="none" stroke="#B2AC8A" stroke-width="2"/>
                            </g>
                        </g>
                    </svg>
                    </a>
                    </button>
                </div>
            </div>`;
            container.appendChild(teaser);
        });

        if (currentIndex < newsData.length) {
            new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        loadItems();
                        observer.unobserve(entry.target);
                    }
                });
            }).observe(loadMoreButton);
        }
        setTimeout(function () {
            equalHeightTeaserHeadlines();
        },3000)

    }
}
