import Isotope from "isotope-layout";

export function referenzOverview() {
    const referenzOverview = document.querySelector('.referenz-overview');

    if (!referenzOverview) {
        return;
    }
    let columnWidth = 570;

    if(window.matchMedia('(min-width: 820px)').matches) {
        columnWidth = 410;
    }
    if(window.matchMedia('(min-width: 992px)').matches) {
        columnWidth = 460;
    }
    if(window.matchMedia('(min-width: 1140px)').matches) {
        columnWidth = 570;
    }
    if(window.matchMedia('(min-width: 1365px)').matches) {
        columnWidth = 455;
    }
    if(window.matchMedia('(min-width: 1710px)').matches) {
        columnWidth = 570;
    }

    const iso = new Isotope('.grid', {
        itemSelector: '.element-item',
        masonry: {
            columnWidth: columnWidth
        },
        transitionDuration: '0.5s'
    });

    const filtersElem = document.querySelector('.filter-button-group');
    filtersElem.addEventListener('click', event => {
        if (!event.target.matches('button')) {
            return;
        }
        const filterValue = event.target.getAttribute('data-filter');
        iso.arrange({ filter: filterValue });
    });

    const buttonGroups = document.querySelectorAll('.button-group');
    buttonGroups.forEach(buttonGroup => radioButtonGroup(buttonGroup));

    function radioButtonGroup(buttonGroup) {
        buttonGroup.addEventListener('click', event => {
            if (!event.target.matches('button')) {
                return;
            }
            buttonGroup.querySelector('.active').classList.remove('active');
            event.target.classList.add('active');
        });
    }
}
