export function akkordeon() {

    const accInput = document.querySelectorAll('.block-accordion input');

    accInput.forEach((input) => {
        input.addEventListener('click', () => {

            if (!input.hasAttribute('checked')) {
                accInput.forEach((element) => {
                    element.removeAttribute('checked');
                    input.checked = false;
                    setTimeout(()=> {
                        element.parentElement.classList.remove('active');
                    }, 300)

                });
                setTimeout(() => {
                    input.parentElement.classList.add('active');
                },300)

                input.setAttribute('checked', "");
                input.checked = true;
            } else {
                input.checked = false;
                input.removeAttribute('checked');
                setTimeout(() => {
                    input.parentElement.classList.remove('active');
                }, 300)

            }

        });
    });
}
