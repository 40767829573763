export function cookie() {
    // ----------------------------------------------------
// LOAD IN app.js
//
// require('./components/cookies-eu-banner.js');
// ----------------------------------------------------

// ----------------------------------------------------
// PAGE READY CHECK
// ----------------------------------------------------

// run initialization after page has loaded:
    let readyStateCheckInterval = setInterval(function() {
        if (document.readyState === "complete") {
            clearInterval(readyStateCheckInterval);
            cookiesEuBanner(cookieValues);
        }
    }, 10);

// ----------------------------------------------------
// COOKIE VALUES
// ----------------------------------------------------

// set cookie values:
// name:                cookie name (has to match toggle button ids)
// value:               initial value
// fixed:               can be changed with checkboxes
// code:                execute this code if the user consented
// deleteCookieNames:   delete these external cookies if the user did not consent

    let cookieValues = [
        {
            name: "cookies-eu-necessary",
            value: true,
            fixed: true,
            code: function() {
                //console.log("necessary code executed");

                googleMaps();
            },
            deleteCookieNames: []
        },
        {
            name: "cookies-eu-statistics",
            value: false,
            fixed: false,
            code: function() {
                //console.log("statistics code executed");

                //googleAnalytics();
                googleTagManagerScript();

                //console.log("cookies: ", window.googleTagManager);
            },
            deleteCookieNames: window.deleteCookies.split(',')
        },
        /*{
            name: "cookies-eu-comfort",
            value: false,
            fixed: false,
            code: function() {
                //console.log("comfort code executed");
            },
            deleteCookieNames: []
        },
        {
            name: "cookies-eu-personalisation",
            value: false,
            fixed: false,
            code: function() {
                //console.log("personalisation code executed");
            },
            deleteCookieNames: []
        }*/
    ];

// ----------------------------------------------------
// HIDDEN INITIALLY
// ----------------------------------------------------

// hidden initially on these pages, example: "/de/legal/datenschutz"

    const hiddenInit = [
        window.dataProtectionDoc,
        window.legalDoc,
        window.conditionsDoc
    ];

// ----------------------------------------------------
// DOMAIN NAME
// ----------------------------------------------------

// deal with www.domain.com and domain.com cookie mix up:
// let domainName = document.domain.replace("www.", "");
// analytics sets cookies on highest domainname, easier to just set the domainname directly

    const domainName = window.cookieDomain;

// ----------------------------------------------------
// FUNCTIONS FOR INDIVIDUAL COOKIES
// ----------------------------------------------------

// Google Maps:
    function googleMaps() {
        // initMap() and loadMap() in view file

        // get map element
        let map = document.getElementById('map');

        if (map) {
            // script exists
            if (typeof google !== undefined && typeof google === 'object' && typeof google.maps === 'object') {
                initMap();

                // load script
            } else {
                loadMap();
            }
        }
    }

// Google Analytics:
    /* function googleAnalytics() {
        if (window.googleAnalyticsUA) {
            let script = document.createElement('script');

            script.src = "https://www.googletagmanager.com/gtag/js?id=" + window.googleAnalyticsUA;

            script.onload = function () {
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                    dataLayer.push(arguments);
                }
                gtag('js', new Date());
                gtag('config', window.googleAnalyticsUA , {'anonymize_ip': true});
            };

            document.head.appendChild(script);
        }
    } */

    function googleTagManagerScript() {
        if (window.googleTagManager) {
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer',window.googleTagManager);
        }
    }

// ----------------------------------------------------
// SHOW POP UP AFTER TIMEOUT
// ----------------------------------------------------

    /* function showPopup() {
        let popupBanner = document.getElementById('popupBanner');
        let popupBannerButton = document.getElementById('popupBannerButton');
        let popupBannerClose = document.getElementById('popupBannerClose');

        // show popup:
        // 10 seconds after hideBanner
        // older than 7 days (604800000) -> has been send or closed before
        if (popupBanner && localStorage.getItem('popupFormSendDate') <= Date.now() - 604800000) {
            setTimeout(function() {
                popupBanner.classList.add('is-active');
            }, 10000);
        }

        // close banner
        if (popupBannerClose && popupBanner) {
            popupBannerClose.addEventListener('click', function() {
                popupBanner.classList.remove('is-active');
                localStorage.setItem('popupFormSendDate', Date.now());
            })
        }

        // follow link and close banner
        if (popupBannerButton && popupBanner) {
            popupBannerButton.addEventListener('click', function() {
                popupBanner.classList.remove('is-active');
                localStorage.setItem('popupFormSendDate', Date.now());
            })
        }
    } */

// ----------------------------------------------------
// MAIN FUNCTION
// ----------------------------------------------------

    function cookiesEuBanner(cookieValues) {

        // get banner:
        let banner = document.getElementById("cookies-eu-banner");

        // only run if banner is available on the page:
        if (banner) {

            banner.style.display = "block";

            // get save and revoke buttons
            let saveButton = document.getElementById("cookies-eu-save");
            let saveAllButton = document.getElementById("cookies-eu-save-all");
            let saveMinimumButton = document.getElementById("cookies-eu-save-minimum");
            let revokeButton = document.getElementById("cookies-eu-revoke");
            let revokeButtonMobile = document.getElementById("cookies-eu-revoke-mobile");

            // destroy banner element for DoNotTrack and bots and exit main code:
            if (botCheck(banner)) {
                return false;
            }

            // allow interaction with checkmark buttons for not fixed cookies
            cookieValues.forEach(function(e) {
                if (e.fixed === false) {
                    toggleClassListener(document.getElementById(e.name), banner);
                } else {
                    document.getElementById(e.name).setAttribute("disabled", "");
                }
            })

            // enable click listeners for all buttons
            saveListener(saveButton, banner);
            saveAllListener(saveAllButton, banner);
            saveMinimumListener(saveMinimumButton, banner);
            revokeListener(revokeButton, banner);
            revokeListener(revokeButtonMobile, banner);

            // only show banner if cookies dont exist:
            if (hasConsented(cookieValues) === true) {
                hideBanner(banner);
            } else {

                // hide banner for exception sites:
                let hide = false;

                hiddenInit.forEach(function(e) {
                    if (window.location.pathname === e) {
                        hide = true;
                    }
                })

                if (hide) {
                    hideBanner(banner);
                } else {
                    showBanner(banner);
                }
            }
        }
    }

// ----------------------------------------------------
// sub functions:
// ----------------------------------------------------

// -------------------
// remove banner from website for doNotTrack browser setting and crawlers
// -------------------

    function botCheck(banner) {
        let bots = /bot|crawler|spider|crawling/i;
        let isBot = bots.test(navigator.userAgent);
        let doNotTrack = navigator.doNotTrack || navigator.msDoNotTrack || window.doNotTrack;
        let isToTrack = (doNotTrack !== null && doNotTrack !== undefined) ? (doNotTrack && doNotTrack !== 'yes' && doNotTrack !== 1 && doNotTrack !== '1') : true;

        if (isBot || !isToTrack) {
            removeBanner(banner);
            return true;
        }
    }

// -------------------
// iterate over checkmark buttons, toggle checkmark, enable/disable cookie code
// -------------------

    function toggleClassListener(button, banner) {
        if (button && banner) {
            button.addEventListener("click", function() {
                if (button.classList.contains("consented")) {
                    button.classList.remove("consented");

                    cookieValues.forEach(function(e) {
                        if (e.name === button.id) {
                            e.value = false;
                        }
                    })
                } else {
                    button.classList.add("consented");

                    cookieValues.forEach(function(e) {
                        if (e.name === button.id) {
                            e.value = true;
                        }
                    })
                }
            })
        }
    }

// -------------------
// consent to selected cookies and hide banner
// -------------------

    function saveListener(button, banner) {
        if (button && banner) {
            button.addEventListener("click", function() {
                save('selected');
                hideBanner(banner);
            })
        }
    }

// -------------------
// consent to selected cookies and hide banner
// -------------------

    function saveMinimumListener(button, banner) {
        if (button && banner) {
            button.addEventListener("click", function() {
                save('minimum');
                hideBanner(banner);
            })
        }
    }

// -------------------
// consent to all cookies and hide banner
// -------------------

    function saveAllListener(button, banner) {
        if (button && banner) {
            button.addEventListener("click", function() {
                save('all');
                hideBanner(banner);
            })
        }
    }

// -------------------
// show banner if cookie settings button is clicked
// -------------------

    function revokeListener(button, banner) {
        if (button && banner) {
            button.addEventListener("click", function() {
                showBanner(banner);
            })
        }
    }

// -------------------
// send cookie consent to webserver
// -------------------

    function sendConsentToServer() {

        // url has to match cookie controller
        let url = '/cookie-consent';

        // build query from cookie values
        let query = '?';

        for (let i = 0; i < cookieValues.length; i++) {
            query += cookieValues[i].name + '=' + getLocalStorage(cookieValues[i].name);

            if (i !== cookieValues.length - 1) {
                query += '&';
            }
        }

        // call consent controller, returns json
        fetch(url + query)
            .then(function(response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            }).then(function(response) {
            //console.log(response);
        }).catch(function(error) {
            //console.log(error);
        });

    }

// -------------------
// save cookies
// -------------------

// cookies == all
// cookies == selected
// cookies == minimum (fallback)

    function save(cookies) {
        for (let i = 0; i < cookieValues.length; i++) {

            // save all
            if (cookies === 'all') {
                setLocalStorage(cookieValues[i].name, "true");
                document.getElementById(cookieValues[i].name).classList.add("consented");

                cookieValues[i].code();

                // save selected
            } else if (cookies === 'selected') {
                setLocalStorage(cookieValues[i].name, cookieValues[i].value);

                if (getLocalStorage(cookieValues[i].name) === "true" || getLocalStorage(cookieValues[i].name) === true) {
                    cookieValues[i].code();
                } else {
                    cookieValues[i].deleteCookieNames.forEach(function(e) {
                        deleteCookie(e);
                    })
                }

                // save minimum
            } else if (cookies === 'minimum') {
                if (cookieValues[i].fixed) {
                    cookieValues[i].code();
                } else {
                    setLocalStorage(cookieValues[i].name, false);

                    cookieValues[i].deleteCookieNames.forEach(function(e) {
                        deleteCookie(e);
                    })
                }
            }
        }
    }

// -------------------
// check if user selected a banner save option once
// -------------------

    function hasConsented(cookieValues) {
        let atLeastOneCookie = false;

        cookieValues.forEach(function(e) {
            if (getLocalStorage(e.name) === "true") {
                atLeastOneCookie = true;
                e.code();
            } else if (getLocalStorage(e.name) === "false") {
                atLeastOneCookie = true;
            }
        })

        if (atLeastOneCookie === true) {
            return true;
        } else {
            return false
        }
    }

// -------------------
// hide banner
// -------------------

    function hideBanner(banner) {
        if (banner) {
            banner.classList.remove("is-active");
            //body.classList.remove("no-scrollbar");
            //document.querySelector("body").style = "overflow-y: auto";
        }

        //showPopup();
    }

// -------------------
// show banner
// -------------------

    function showBanner(banner) {
        if (banner) {
            cookieValues.forEach(function (e) {
                if (getLocalStorage(e.name) === true || getLocalStorage(e.name) === "true") {
                    e.value = true;
                } else if (getLocalStorage(e.name) === false || getLocalStorage(e.name) === "false") {
                    e.value = false;
                }
            })

            applyClasses(cookieValues);

            banner.classList.add("is-active");
            //body.classList.add("no-scrollbar");
            //document.querySelector("body").classList.add("no-scrollbar");
            //document.querySelector("body").style = "overflow-y: hidden";
        }
    }

// -------------------
// remove banner from website
// -------------------

    function removeBanner(banner) {
        if (banner && banner.parentNode) {
            banner.parentNode.removeChild(banner);
        }
    }

// -------------------
// add or remove checkmark for selected cookies on banner
// -------------------

    function applyClasses(cookieValues) {
        cookieValues.forEach(function(e) {
            if (e.value === true) {
                document.getElementById(e.name).classList.add("consented");
            } else {
                document.getElementById(e.name).classList.remove("consented");
            }
        })
    }

// ----------------------------------------------------
// Cookies
// ----------------------------------------------------

// -------------------
// delete cookie data from browser (check first if cookie exists to prevent creating empty cookie)
// -------------------

    function deleteCookie(name) {
        if (document.cookie.indexOf(name) >= 0) {
            document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure; domain=' + domainName;
        }
    }

// ----------------------------------------------------
// localStorage
// ----------------------------------------------------

// -------------------
// write localStorage data to browser
// -------------------

    function setLocalStorage(name, value) {
        localStorage.setItem(name, value);
    }

// -------------------
// read localStorage data from browser
// -------------------

    function getLocalStorage(name) {
        if (localStorage.getItem(name)) {
            return localStorage.getItem(name);
        } else return null;
    }

    let cookieAccBtn = document.getElementById('cookies-eu-details');
    let accContent   = document.getElementById('cookie-acc-content');

    if (cookieAccBtn) {
        cookieAccBtn.addEventListener('click', () => {
            accContent.classList.toggle('show');
            cookieAccBtn.classList.toggle('open');
            cookieAccBtn.parentNode.classList.toggle('open');
        });
    }
}
