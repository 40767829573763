import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import './styles.scss';
import {lazyImages} from "./js/lazy-images";
import {header} from "./js/header";
import {heroHeadlines} from "./js/hero";
import {accordions} from "./js/accordions";
import {stoerer} from "./js/stoerer";
import {equalHeightTeaserHeadlines} from "./js/equal-height-teaser";
import {teaserModal} from "./js/teaser-modal";
import {akkordeon} from "./js/akkordeon";
import {newsList} from "./js/news-list";
import {cookie} from "./js/cookies-eu-banner";
import {referenzOverview} from "./js/referenz-overview";
import {searchForm, searchQuery} from "./js/search";

accordions();
stoerer();
lazyImages();
header();
heroHeadlines();
teaserModal();
akkordeon();
newsList();
equalHeightTeaserHeadlines();
cookie();
referenzOverview();
searchForm();
searchQuery()

const swiper = new Swiper('.swiper-hero', {
    loop: true,
    autoplay:
        {
            delay: 7000,
        }
});

// Get all swiper containers
let swiperContainers = document.querySelectorAll('.swiper-container');

// Initialize each swiper
swiperContainers.forEach((container, index) => {
    new Swiper(container, {
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
});

let swiperTeaserFilling = document.querySelectorAll('.swiper-teaser-filling');

swiperTeaserFilling.forEach((container, index) => {
    new Swiper(container, {
        autoplay:
            {
                delay: 5000,
            },
        loop: true,
        pagination: {
            el: ".swiper-pagination",
            type: "fraction"
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });
});


const swiperSlider = new Swiper(".swiper-slider", {
    loop: true,
    slidesPerView: 2,
    spaceBetween: 30,
    centeredSlides: true,
    /*pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },*/
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
});
