export function lazyImages() {
    const blurredImageDiv = document.querySelectorAll(".blurred-img")
    blurredImageDiv.forEach((div)=> {
       const img = div.querySelector('img.lazy');
        function loaded() {
            div.classList.add("loaded")
        }
        if (img.complete) {
            loaded()
        } else {
            img.addEventListener("load", loaded)
        }
    });
}
