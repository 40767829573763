export function equalHeightTeaserHeadlines() {
    let teaserContainers = document.querySelectorAll('.teaser-container');

    function resetHeights() {
        teaserContainers.forEach((container) => {
            let items = {
                teasers: container.querySelectorAll('.teaser-text'),
                headlines: container.querySelectorAll('.teaser-text .headline'),
                titleText: container.querySelectorAll('.teaser-text .title-text')
            };

            Object.keys(items).forEach(key => {
                items[key].forEach(item => {
                    item.style.height = 'auto';
                });
            });
        });
    }

    function adjustHeights() {
        if (window.innerWidth < 576) {
            resetHeights();
            return;
        }

        teaserContainers.forEach((container) => {
            let items = {
                teasers: container.querySelectorAll('.teaser-text'),
                headlines: container.querySelectorAll('.teaser-text .headline'),
                titleText: container.querySelectorAll('.teaser-text .title-text')
            }

            Object.keys(items).forEach(key => {
                items[key].forEach(item => {
                    item.style.height = 'auto';
                });
            });

            let maxTeaserHeight = 0;
            let maxHeadlineHeight = 0;
            let maxTitleHeight = 0;

            Object.keys(items).forEach(key => {
                items[key].forEach(item => {
                    let height = item.offsetHeight;

                    switch (key) {
                        case 'teasers':
                            if (height > maxTeaserHeight) maxTeaserHeight = height;
                            break;
                        case 'headlines':
                            if (height > maxHeadlineHeight) maxHeadlineHeight = height;
                            break;
                        case 'titleText':
                            if (height > maxTitleHeight) maxTitleHeight = height;
                            break;
                    }
                });
            });

            // Höhen setzen
            Object.keys(items).forEach(key => {
                items[key].forEach(item => {
                    switch (key) {
                        case 'teasers':
                            item.style.height = maxTeaserHeight + "px";
                            break;
                        case 'headlines':
                            item.style.height = maxHeadlineHeight + "px";
                            break;
                        case 'titleText':
                            item.style.height = maxTitleHeight + "px";
                            break;
                    }
                });
            });
        });
    }

    adjustHeights();
    window.addEventListener('resize', adjustHeights);
}

/*export function equalHeightTeaserHeadlines() {
    let teaserContainers = document.querySelectorAll('.teaser-container');

    function adjustHeights() {
        teaserContainers.forEach((container) => {
            let items = {
                teasers: container.querySelectorAll('.teaser-text'),
                headlines: container.querySelectorAll('.teaser-text .headline'),
                titleText: container.querySelectorAll('.teaser-text .title-text')
            }

            Object.keys(items).forEach(key => {
                items[key].forEach(item => {
                    item.style.height = 'auto';
                });
            });

            let maxTeaserHeight = 0;
            let maxHeadlineHeight = 0;
            let maxTitleHeight = 0;

            Object.keys(items).forEach(key => {
                items[key].forEach(item => {
                    let height = item.offsetHeight;

                    switch (key) {
                        case 'teasers':
                            if (height > maxTeaserHeight) maxTeaserHeight = height;
                            break;
                        case 'headlines':
                            if (height > maxHeadlineHeight) maxHeadlineHeight = height;
                            break;
                        case 'titleText':
                            if (height > maxTitleHeight) maxTitleHeight = height;
                            break;
                    }
                });
            });

            // Höhen setzen
            Object.keys(items).forEach(key => {
                items[key].forEach(item => {
                    switch (key) {
                        case 'teasers':
                            item.style.height = maxTeaserHeight + "px";
                            break;
                        case 'headlines':
                            item.style.height = maxHeadlineHeight + "px";
                            break;
                        case 'titleText':
                            item.style.height = maxTitleHeight + "px";
                            break;
                    }
                });
            });
        });
    }
    adjustHeights();
    window.addEventListener('resize', adjustHeights);
}*/

