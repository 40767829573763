export function heroHeadlines() {
    // Auswahl der Ziel-Elemente
    const headlines = document.querySelectorAll('.headlines');

    // Erstellung des Intersection Observers
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const title = entry.target.querySelector('.title');
                const titleText = entry.target.querySelector('.title-text');
                const headline = entry.target.querySelector('.headline');
                const h1 = entry.target.querySelector('h1');
                const lead = entry.target.querySelector('.lead');
                const heroContentBox = entry.target.querySelector('.hero-content-box');
                const textContainer = entry.target.querySelector('.text-container');
                const iconContainer = entry.target.querySelector('.icon-container');

                if (iconContainer) {
                    const items = iconContainer.querySelectorAll('.item');

                    items.forEach((item, index) => {
                        setTimeout(() => {
                            item.classList.add('reveal');
                        }, 300 * index); // Jedes .item erhält die .reveal Klasse mit einer Verzögerung von 500ms
                    });
                }

                // Hinzufügen der "reveal"-Klasse zur "title"
                if (title) {
                    title.classList.add('reveal');
                }
                if (titleText) {
                    titleText.classList.add('reveal');
                }

                if (h1) {
                    h1.classList.add('reveal');
                }
                if (lead) {
                    lead.classList.add('reveal');
                }

                // Hinzufügen der "reveal"-Klasse zur "headline" nach 300ms
                if (headline) {
                    setTimeout(() => {
                        headline.classList.add('reveal');
                    }, 150);
                }
                if (heroContentBox) {
                    heroContentBox.classList.add('animate');
                }
                if (textContainer) {
                    setTimeout(() => {
                    textContainer.classList.add('reveal');
                    }, 300);
                }

                // Unobserve the current target
                observer.unobserve(entry.target);
            }
        });
    }, { rootMargin: '0px', threshold: 0.1 });

    // Beginn des Observierens des Elements
    headlines.forEach(headline => observer.observe(headline));


   /* function setMarginBottom() {
        let parentElement = document.querySelector('.hero');
        if (!parentElement) return;

        // Erhalte die aktuelle Höhe des parentElement
        let parentHeight = parentElement.offsetHeight;

        // Erhalte die aktuelle Fensterbreite
        let windowWidth = window.innerWidth;

        // Berechne den neuen marginBottom basierend auf der Fensterbreite
        let marginBottomValue = (windowWidth < 576) ? (parentHeight * 0.3) : (parentHeight + 100);

        parentElement.style.marginBottom = `${marginBottomValue}px`;
    }

// Rufe die Funktion beim Laden der Seite auf
    setMarginBottom();

// Setze den EventListener, um die Funktion erneut aufzurufen, wenn das Fenstergröße geändert wird
    window.addEventListener('resize', setMarginBottom);*/



    /*    // Erhalte das Elternelement
        let parentElement = document.querySelector('.hero');

        // Erhalte das erste Kindelement
        //let childElement = parentElement.querySelector('.lead');
        let childElement = parentElement

        if (!childElement) {
            return;
        }

        // Erhalte die Höhe des Kindelements
        let childHeight = childElement.offsetHeight;

        // Berechne die neue Höhe für das Elternelement
        let newParentHeight = childHeight - 100;

        // Stelle sicher, dass newParentHeight nicht negativ ist
        if (newParentHeight < 0) {
            newParentHeight = 0;
        }

        // Setze die Höhe des Elternelements
        parentElement.style.marginBottom = `${newParentHeight}px`;*/

}


