export function header() {
    const burgerMenu = document.querySelector('.burger-negativ');
    const burgerMenuMobile = document.querySelector('.burger-positiv');
    const fullscreenMenu = document.querySelector('.fullscreen-menu');
    const closeFullscreenMenu = document.querySelector('.close-fullscreen-menu');
    const body = document.querySelector('body');
    const mainNavItem = fullscreenMenu.querySelectorAll('.main-nav a');
    const serviceNavItem = fullscreenMenu.querySelectorAll('.service-nav a');
    const plant = fullscreenMenu.querySelector('.plant');
    const footerNavMenu = fullscreenMenu.querySelector('.footer-nav-menu');

    function openFullscreenMenu() {
        fullscreenMenu.classList.add('show');
        fullscreenMenu.scrollIntoView({ block: 'start' });
        body.classList.add('no-scrolling');
        mainNavItem.forEach((element, index) => {
            setTimeout(() => {
                element.classList.add('slide-in');
            }, 150 * index);
        });
        let delayForServiceItems = 150 * mainNavItem.length;
        serviceNavItem.forEach((element, index) => {
            setTimeout(() => {
                element.classList.add('slide-in');
                footerNavMenu.classList.add('slide-up');
            }, delayForServiceItems + 150 * index);
        });
        let delayForPlant = delayForServiceItems + 10 * serviceNavItem.length;
        setTimeout(() => {
            plant.classList.add('slide-up');
        }, delayForPlant);
    }

    if (burgerMenu) {
        burgerMenu.addEventListener('click', openFullscreenMenu);
    }

    if (burgerMenuMobile) {
        burgerMenuMobile.addEventListener('click', openFullscreenMenu);
    }

    closeFullscreenMenu.addEventListener('click', () => {
        fullscreenMenu.classList.remove('show');
        body.classList.remove('no-scrolling');
        mainNavItem.forEach((element) => {
            element.classList.remove('slide-in');
        });
        serviceNavItem.forEach((element) => {
            element.classList.remove('slide-in');
        });
        plant.classList.remove('slide-up');
        footerNavMenu.classList.remove('slide-up');
    });

    const header = document.querySelector('header');
    let scrollableElement = document.getElementById('body');
    let wheelEventListener = null;


    function findScrollDirectionOtherBrowsers(event) {
        let delta;
        if (event.wheelDelta) {
            delta = event.wheelDelta;
        } else {
            delta = -1 * event.deltaY;
        }
        if (delta < 0) {
            header.classList.remove('up');
        } else if (delta > 0) {
            if (window.scrollY > 277) {
                header.classList.add('up');
            }
        }
    }

    wheelEventListener = findScrollDirectionOtherBrowsers;
    scrollableElement.addEventListener('wheel', wheelEventListener);
    window.addEventListener('resize', function (event) {
        if (window.innerWidth >= 1560 && wheelEventListener == null) {
            wheelEventListener = findScrollDirectionOtherBrowsers;
            scrollableElement.addEventListener('wheel', wheelEventListener);
        } else if (window.innerWidth < 1560 && wheelEventListener != null) {
            scrollableElement.removeEventListener('wheel', wheelEventListener);
            wheelEventListener = null;
        }
    });

    const targetNav = document.getElementById('main-navigation');
    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0
    };

    function handleIntersect(entries, observer) {
        entries.forEach(function (entry) {
            if (entry.intersectionRatio > 0) {
                entry.target.classList.add('show');
                header.classList.remove('up');
            } else {
                entry.target.classList.remove('show');
            }
        });
    }

    const observer = new IntersectionObserver(handleIntersect, options);
    observer.observe(targetNav);


    let lastScrollPosition = window.scrollY;

    function handleScroll() {
        let currentScrollPosition = window.scrollY;

        if (currentScrollPosition < lastScrollPosition && currentScrollPosition > 277) {
            header.classList.add('up');
        } else {
            header.classList.remove('up');
        }

        lastScrollPosition = currentScrollPosition;
    }

    window.addEventListener('scroll', handleScroll);

}

/*
export function header() {
    const burgerMenu = document.querySelector('.burger-negativ');
    const burgerMenuMobile = document.querySelector('.burger-positiv');
    const fullscreenMenu = document.querySelector('.fullscreen-menu');
    const closeFullscreenMenu = document.querySelector('.close-fullscreen-menu');
    const body = document.querySelector('body');
    const mainNavItem = fullscreenMenu.querySelectorAll('.main-nav a');
    const serviceNavItem = fullscreenMenu.querySelectorAll('.service-nav a')
    const plant = fullscreenMenu.querySelector('.plant');
    const footerNavMenu = fullscreenMenu.querySelector('.footer-nav-menu');

    burgerMenu.addEventListener('click', () => {
        fullscreenMenu.classList.add('show');
        body.classList.add('no-scrolling');
        mainNavItem.forEach((element, index) => {
            setTimeout(() => {
                element.classList.add('slide-in');
            }, 150 * index);
        });
        let delayForServiceItems = 150 * mainNavItem.length;
        serviceNavItem.forEach((element, index) => {
            setTimeout(() => {
                element.classList.add('slide-in');
                footerNavMenu.classList.add('slide-up');
            }, delayForServiceItems + 150 * index);
        });
        let delayForPlant = delayForServiceItems + 10 * serviceNavItem.length;
        setTimeout(() => {
            plant.classList.add('slide-up');
        }, delayForPlant);
    });
    closeFullscreenMenu.addEventListener('click', () => {
        fullscreenMenu.classList.remove('show');
        body.classList.remove('no-scrolling');
        mainNavItem.forEach((element) => {
            element.classList.remove('slide-in');
        });
        serviceNavItem.forEach((element) => {
            element.classList.remove('slide-in');
        });
        plant.classList.remove('slide-up');
        footerNavMenu.classList.remove('slide-up');
    });
    const header = document.querySelector('header');
    let scrollableElement = document.getElementById('body');
    let wheelEventListener = null;
    function findScrollDirectionOtherBrowsers(event) {
        let delta;
        if (event.wheelDelta) {
            delta = event.wheelDelta;
        } else {
            delta = -1 * event.deltaY;
        }
        if (delta < 0) {
            header.classList.remove('up');
        } else if (delta > 0) {
            if (window.scrollY > 277) {
                header.classList.add('up');
            }
        }
    }
    wheelEventListener = findScrollDirectionOtherBrowsers;
    scrollableElement.addEventListener('wheel', wheelEventListener);
    window.addEventListener('resize', function (event) {
        if (window.innerWidth >= 1560 && wheelEventListener == null) {
            wheelEventListener = findScrollDirectionOtherBrowsers;
            scrollableElement.addEventListener('wheel', wheelEventListener);
        } else if (window.innerWidth < 1560 && wheelEventListener != null) {
            scrollableElement.removeEventListener('wheel', wheelEventListener);
            wheelEventListener = null;
        }
    });
    const targetNav = document.getElementById('main-navigation');
    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0
    }
    function handleIntersect(entries, observer) {
        entries.forEach(function (entry) {
            if (entry.intersectionRatio > 0) {
                entry.target.classList.add('show');
                header.classList.remove('up');
            } else {
                entry.target.classList.remove('show');
            }
        });
    }
    const observer = new IntersectionObserver(handleIntersect, options);
    observer.observe(targetNav);
}
*/
